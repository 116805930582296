import { axios } from "./axios";

export class APIManager {


    static async get_agent(agent: string) {
        const request = await axios.get(`/agent/${agent}`)
        const response = request.data
        return response
    }
}