import { APIManager } from "../components/api/manager"
import { lazy, Suspense, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const Template1 =  lazy(() => import('../components/templates/template-1.tsx'))
const Template2 =  lazy(() => import('../components/templates/template-2.tsx'))

const templateMapping = {
    "template-1": <Template1 />,
    "template-2": <Template2 />
}

export default function Call() {
    const [params] = useSearchParams()
    const [assistant, setAssistant] = useState<any>()
    const [domain, setDomain] = useState<string>()

    useEffect(() => {
        if (params?.get("assistantId")) {
            (async () => {
                const _assistant = await APIManager.get_agent(params!.get("assistantId")!)
                setAssistant(_assistant)
            })()
        }
    }, [ params ])

    useEffect(() => {
        if (typeof "window" !== undefined) {
            setDomain( window.location.hostname)
        }
    }, [])

    return (
        <Suspense>
            {/* @ts-ignore */}
           {templateMapping[assistant?.meta?.templates?.[domain]]}
            
        </Suspense>
    )
}