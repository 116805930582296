import Axios from 'axios';

const axios = Axios.create({
    baseURL: 'https://dev.api.electricspeech.co/v1',
    headers: {
        Authorization: `Bearer demo-key`
    }
})

export {
    axios
}