import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Router, Routes } from 'react-router-dom';
import Call from './pages/call';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Call />} />
      <Route path='/call' element={<Call />} />
    </Routes>
  );
}

export default App;
